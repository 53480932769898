<template>
  <v-card>
    <v-card-title>
      {{ event.name }}
    </v-card-title>
    <v-card-subtitle>
      {{ event.schedule }}
    </v-card-subtitle>
    <v-card-subtitle>
      <a :href="event.url" target="_blank">{{ event.url }}</a>
    </v-card-subtitle>
    <v-card-text>
      {{ event.details }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["event"],
};
</script>
