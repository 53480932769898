<template>
  <main-container>
    <v-toolbar class="mb-5">
      <v-btn text @click="setNow">Now</v-btn>
      <v-spacer />
      <v-icon x-large @click="changeMonth(-1)">mdi-menu-left</v-icon>
      <div style="width: 8rem">
        {{ calMonth }}
        {{ calYear }}
      </div>
      <v-icon x-large @click="changeMonth(1)">mdi-menu-right</v-icon>
      <v-spacer />

      <v-combobox
        :items="eventCombo"
        label="Search"
        v-model="search"
        class="mt-3"
        dense
        prepend-icon="mdi-magnify"
        clearable
      />
    </v-toolbar>

    <v-calendar
      :events="eventList"
      :start="calStart"
      type="month"
      @click:day="showDay"
      v-if="dataLoaded"
    />

    <v-dialog
      :max-width="$vuetify.breakpoint.mdAndUp ? '45vw' : '99vw'"
      v-model="dayDetails.visible"
    >
      <day-card
        :day="dayDetails"
        :events="events"
        :eventList="eventList"
        @close-dialog="dayDetails.visible = false"
      ></day-card>
    </v-dialog>
  </main-container>
</template>

<script>
import { mapState } from "vuex";
import MainContainer from "../components/MainContainer.vue";
import moment from "moment-timezone";
import DayCard from "../components/Events/DayCard.vue";

const convertTime = (time, zone) => {
  if (!time) return null;
  if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(time)) return time;
  return moment.tz(time, zone).local().format("YYYY-MM-DD HH:mm");
};

export default {
  components: { MainContainer, DayCard },
  data: () => ({
    dayDetails: {},
    calMonth: moment().format("MMM"),
    calYear: moment().format("YYYY"),
    search: "",
  }),
  computed: {
    ...mapState(["events", "dataLoaded"]),
    calStart() {
      if (this.search && this.search != "") {
        const event = this.eventList.filter(({ name }) =>
          name.toLowerCase().includes(this.search.toLowerCase())
        );

        if (event.length == 1) {
          this.setMonth(event[0].start);
          return event[0].start;
        }
        return;
      }

      return moment(`${this.calMonth} ${this.calYear}`, "MMMM YYYY").format("YYYY-MM-DD");
    },
    eventCombo() {
      return [...this.eventList].map(({ name }) => name).sort();
    },
    eventList() {
      const eventData = [...this.events]
        .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
        .map((event) => {
          const ret = {
            name: event.name,
            start: convertTime(event.startDate, event.tz, event),
            end: convertTime(event.endDate, event.tz, event),
            timed: true,
          };
          return ret;
        });

      return eventData;
    },
  },
  methods: {
    setMonth(date) {
      date;
      this.calMonth = moment(`${date}`, "YYYY-MM-DD").format("MMMM");
      this.calYear = moment(`${date}`, "YYYY-MM-DD").format("YYYY");
    },
    showDay(day) {
      this.dayDetails = { ...day, visible: true };
    },
    setNow() {
      this.calMonth = moment().format("MMM");
      this.calYear = moment().format("YYYY");
    },
    changeMonth(direction) {
      this.search = "";
      const curStart = moment(`${this.calMonth} ${this.calYear}`, "MMMM YYYY");
      const newStart =
        direction < 0 ? curStart.subtract(1, "month") : curStart.add(1, "month");
      this.calMonth = newStart.format("MMM");
      this.calYear = newStart.format("YYYY");
    },
  },
};
</script>
