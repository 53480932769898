<template>
  <div>
    <v-toolbar flat dense dark>
      {{ displayDate }}
      <v-spacer />
      <v-icon @click="$emit('close-dialog')">
        mdi-close
      </v-icon>
    </v-toolbar>
    <v-calendar
      type="day"
      :start="day.date"
      :events="eventList"
      @click:event="showEvent"
    />
    <v-dialog
      v-model="eventDetails.visible"
      :max-width="$vuetify.breakpoint.mdAndUp ? '45vw' : '99vw'"
    >
      <event-card :event="eventDetails"></event-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import EventCard from "@/components/Events/EventCard";
export default {
  props: ["day", "eventList", "events"],
  components: {
    EventCard,
  },
  data: () => ({
    eventDetails: {},
  }),
  computed: {
    displayDate() {
      return moment(this.day.date).format("LL");
    },
  },
  methods: {
    showEvent(e) {
      const event = this.events
        .filter(({ name }) => name == e.event.name)
        .shift();
      this.eventDetails = { ...event, visible: true };
    },
  },
};
</script>
